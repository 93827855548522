<template>
  <div>
    <h1 class="mb-8 mt-2 text-center text-h5">
      Tolerância para pagamento
    </h1>

    <div v-if="loading" class="d-flex justify-center pt-8">
      <v-progress-circular
        indeterminate
        color="primary"
        size="50"
      ></v-progress-circular>
    </div>

    <v-data-table
      v-if="!loading"
      :headers="creditorsHeaders"
      :items="creditors"
      :items-per-page="-1"
      hide-default-footer
      class="elevation-2 creditors"
      @click:row="goToRule"
    >
      <template v-slot:no-data>
        Nenhum credor encontrado
      </template>

      <template v-slot:item.name="{ item }">
        <strong>{{ item.name }}</strong>
      </template>

      <!-- Entrada (cash) -->
      <template v-slot:item.down_payment_tolerance_days="{ item }">
        <div v-if="item.down_payment_tolerance_days != null"
          class="text-lowercase">
          {{ item.down_payment_tolerance_days }} dias
          {{ item.down_payment_tolerance_days_type | daysType }}
        </div>
      </template>

      <!-- Parcelas (colchão) -->
      <template v-slot:item.installment_tolerance_days="{ item }">
        <div v-if="item.installment_tolerance_days != null"
          class="text-lowercase">
          {{ item.installment_tolerance_days }} dias
          {{ item.installment_tolerance_days_type | daysType }}
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    creditorsHeaders: [
      {
        text: 'Credor',
        value: 'name',
        width: '350px',
      },
      {
        text: 'Cash',
        value: 'down_payment_tolerance_days',
      },
      {
        text: 'Colchão',
        value: 'installment_tolerance_days',
      },
    ],
  }),
  computed: {
    ...mapGetters("creditor", {
      creditors: "getAll",
      loading: "isLoading",
    }),
  },
  methods: {
    goToRule(item) {
      this.$router.push({
        name: "Rule",
        params: {
          id: item.rule_id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.creditors {
  margin: auto;
  max-width: 800px;

  ::v-deep tr {
    cursor: pointer;
  }
}
</style>
