<template>
  <v-container>
    <div class="text-center mt-8 mb-4">
      <h1 class="text-h6">
        Variáveis para as mensagens
      </h1>
    </div>

    <div v-if="loading" class="ml-loading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>

    <v-alert v-if="error" type="errorr" prominent text>
      <v-row align="center">
        <v-col class="grow">
          Não foi possível carregar as variáveis.
        </v-col>
        <v-col class="shrink">
          <v-btn color="primary" @click="carregar()">
            Tentar novamente
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <v-card v-if="!loading && !error" class="variaveis">
      <v-data-table
        :headers="headers"
        :items="messageVariable.objects"
        :items-per-page="1000"
        hide-default-footer
        no-data-text="Nenhuma variável encontrada"
        >
        <template v-slot:item.description="{ item }">
          <span v-html="item.description"></span>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
    headers: [
      { text: 'Nome', value: 'name' },
      { text: 'Descrição', value: 'description' },
      { text: 'Exemplo', value: 'example' },
      { text: 'Tamanho', value: 'lenght' },
    ],
    error: false,
    loading: false,
  }),
  created() {
    this.getAllMessageVariable({
      limit: 1000,
      order: 'name',
    });
  },
  methods: {
    ...mapActions('notification', {
      getAllMessageVariable: 'getAll',
    }),
  },
  computed: {
    ...mapGetters('notification', {
      messageVariable: 'list',
    }),
  },
};
</script>

<style scoped lang="scss">
.variaveis {
  margin: auto;
  max-width: 1200px;
}
</style>
