<template>
  <div>
    <v-alert v-if="loadingError" type="error" prominent text>
      <v-row align="center">
        <v-col class="grow"> Não foi possível carregar as notificações. </v-col>
        <v-col class="shrink">
          <v-btn color="primary" @click="loadNotifications()">
            Tentar novamente
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <!-- Conteúdo da página -->
    <v-row v-if="!loadingError">
      <v-row v-if="creditor" class="d-flex flex-row align-center mx-4 mt-4">
        <template>
          <v-dialog v-model="dialogCreateVersion" max-width="400">
            <v-card>
              <v-card-title class="text-h5">
                Deseja criar uma nova versão?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="dialogCreateVersion = false"
                >
                  Não
                </v-btn>
                <v-btn color="green darken-1" text @click="createRule()">
                  Sim
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-row>

      <v-col cols="12" class="d-flex mt-3">
        <div
          v-if="!loading.notifications"
          class="text--secondary">
          {{ allNotifications.length }} notificações
        </div>

        <v-spacer></v-spacer>

        <v-switch
          v-model="showInactiveNotifications"
          label="Exibir inativas"
          @change="loadNotifications(id)"
          hide-details
          class="mt-0 pt-0"
        ></v-switch>
      </v-col>

      <v-col cols="12">
        <v-row>
          <!-- Outros -->
          <v-col cols="12" md="4" v-for="group in notifications.others" :key="group.name">
            <!-- Card -->
            <v-card elevation="1">
              <!-- Cabeçalho do card -->
              <v-toolbar dense flat color="primary">
                <!-- Título -->
                <v-toolbar-title class="white--text font-weight-medium">
                  {{ group.name }}
                </v-toolbar-title>
              </v-toolbar>
              <!-- Conteúdo do card -->
              <v-card-text class="pa-0">
                <!-- Loading -->
                <div
                  v-if="loading.notifications"
                  class="d-flex justify-center pa-3"
                >
                  <v-progress-circular
                    indeterminate
                    color="deep-purple accent-2"
                  ></v-progress-circular>
                </div>

                <!-- Sem dados -->
                <div
                  v-if="!loading.notifications && group.notifications.length == 0"
                  class="d-flex justify-center pa-3"
                  style="color: rgba(0, 0, 0, 0.6)"
                >
                  Nenhuma notificação
                </div>

                <v-list
                  v-if="group.notifications.length"
                  class="notifications-list"
                >
                  <div
                    v-for="(notification, index) in group.notifications"
                    :key="notification.id"
                  >
                    <v-list-item
                      :to="{
                        name: 'Notification',
                        params: { id: notification.id },
                      }"
                      class="notification"
                      v-bind:class="{ inactive: !notification.active }"
                    >
                      <v-list-item-content>
                        <v-list-item-title class="text-body-2">
                          {{ notification.description }}

                          <span v-if="notification.payment_method && !notification.type.startsWith('BOLETO')"
                            style="color: #757575" class="ml-2">
                            {{ notification.payment_method | paymentMethod }}
                          </span>

                          <span v-if="notification.payment_type" style="color: #757575">
                            - {{ notification.payment_type | paymentType }}
                          </span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider
                      v-if="index < group.notifications.length - 1"
                    ></v-divider>
                  </div>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <!-- Pré vencimento -->
      <v-col cols="12">
        <!-- Card -->
        <v-card elevation="1">
          <!-- Cabeçalho do card -->
          <v-toolbar dense flat color="primary">
            <!-- Título -->
            <v-toolbar-title
              class="white--text font-weight-medium"
              style="display: contents"
            >
              Pré-vencimento
            </v-toolbar-title>
          </v-toolbar>
          <!-- Conteúdo do card -->
          <v-card-text class="pa-0">
            <!-- Loading -->
            <div
              class="d-flex justify-center pa-3"
              v-if="loading.notifications"
            >
              <v-progress-circular
                indeterminate
                color="deep-purple accent-2"
              ></v-progress-circular>
            </div>
            <!-- Com mensagens -->
            <v-fade-transition>
              <MatrixNotifications
                :notifications="notificationsBeforeDueDate"
              />
            </v-fade-transition>
            <!-- Sem mensagens -->
            <div
              class="d-flex justify-center pa-3"
              v-if="
                !loading.notifications && notificationsBeforeDueDate.length == 0
              "
            >
              Nenhuma notificação
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-dialog v-model="dialogPublishVersion" max-width="430px">
        <v-form ref="form" lazy-validation @submit="publishCreditorRule">
          <v-card>
            <v-card-title>
              <span class="text-h5"
                >Publicar em produção a versão {{ rule.version }} ?</span
              >
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="rules.description"
                      label="Informe o motivo da alteração."
                      :rules="[
                        () => !!rules.description || 'Este campo é obrigatório',
                      ]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="closePublishDialog()"
              >
                Fechar
              </v-btn>
              <v-btn color="blue darken-1" text type="submit"> Publicar </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <!-- Pós vencimento -->
      <v-col cols="12">
        <!-- Card -->
        <v-card elevation="1">
          <!-- Cabeçalho do card -->
          <v-toolbar dense flat color="primary">
            <!-- Título -->
            <v-toolbar-title class="white--text font-weight-medium">
              Pós-vencimento
            </v-toolbar-title>
          </v-toolbar>
          <!-- Conteúdo do card -->
          <v-card-text class="pa-0">
            <!-- Loading -->
            <div
              class="d-flex justify-center pa-3"
              v-if="loading.notifications"
            >
              <v-progress-circular
                indeterminate
                color="deep-purple accent-2"
              ></v-progress-circular>
            </div>
            <!-- Com mensagens -->
            <v-fade-transition>
              <MatrixNotifications :notifications="notificationsAfterDueDate" />
            </v-fade-transition>
            <!-- Sem mensagens -->
            <div
              class="d-flex justify-center pa-3"
              v-if="
                !loading.notifications && notificationsAfterDueDate.length == 0
              "
            >
              Nenhuma notificação
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <div style="position: relative">

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="pink"
          dark
          fab
          bottom
          right
          fixed
          :to="{ name: 'Notification', query: { rule: rule.id } }"
          style="bottom: 40px;"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <span>Criar notificação</span>
    </v-tooltip>
      
    </div>
  </div>
</template>

<script>
import serviceCreditor from "@/services/creditor";
import serviceNotification from "@/services/notification";
import serviceRule from "@/services/rule";
import { mapActions, mapGetters, mapMutations } from "vuex";
import MatrixNotifications from "./components/MatrixNotifications";

// Ordenação das notificações na tela
const NOTIFICATIONS_ORDER = [
  "PROPOSAL_AGREED",
  "PROPOSAL_CONFIRMED",
  "PROPOSAL_EXTENDED",
  "PROPOSAL_ACTIVE_AGREEMENT",
  "PROPOSAL_WAITING",
  "PROPOSAL_NOT_ELEGIBLE",
  "PROPOSAL_REJECTED",
  "PROPOSAL_EXTENSION_REJECTED",
  "PAYMENT_IDENTIFIED",
  "PAYMENT_EXPIRED",
  "LAST_PAYMENT_IDENTIFIED",
  "BOLETO_SENDED",
  "BOLETO_SCHEDULED",
  "BOLETO_CHANGED",
];

export default {
  components: {
    MatrixNotifications,
  },
  props: {
    /** Id da régua. */
    id: {
      required: true,
    },
  },
  data: () => ({
    /** Todas as réguas do credor. */
    rules: [],

    editNotification: {},

    /** Indica se está carregando o as notificações, a régua ou todas as réguas do credor. */
    loading: {
      notifications: false,
      rule: false,
      rules: false,
    },

    /** Indica se houve um erro ao listar as notificações. */
    loadingError: false,

    /** Lista com todas as notificações da régua. */
    allNotifications: [],

    /** A régua selecionada. */
    rule: {},

    /** Indica se está salvando a régua. */
    saving: {
      rule: false,
    },

    /** Indica se devem ser exibida as notificações inativas. */
    showInactiveNotifications: false,

    dialogMessage: false,
    dialogCreateVersion: false,
    dialogPublishVersion: false,
  }),
  created() {
    this.showInactiveNotifications = this.$route.query.inactive == 'true';

    this.loadRule(this.id);
    this.loadNotifications(this.id);
  },
  beforeRouteUpdate(to, from, next) {
    let ruleId = to.params.id;

    if (this.rule.id != ruleId) {
      this.loadRule(ruleId);
      this.loadNotifications(ruleId);
    }

    return next();
  },
  computed: {
    ...mapGetters("creditor", {
      creditor: "getCreditor",
    }),

    /** Notificações agrupadas por tipo. */
    notifications() {
      /** Notificações sobre boleto enviado, atualizado ou agendado. */
      let boletos = [];

      /** Notificações com as informações da proposta. */
      let proposals = [];

      /** Notificações com as informações dos pagamentos. */
      let pagamentos = [];

      /**
       * Notificações de PRÉ-VENCIMENTO:
       * AP - Antecipação de pagamento
       * L - Lembrete de vencimento
       * V - Vencimento
       */
      let beforeDueDate = {};

      /**
       * Notificações de PRÉ-VENCIMENTO:
       * AP - Antecipação de pagamento
       * L - Lembrete de vencimento
       * V - Vencimento
       */
      let afterDueDate = {};

      // Percorre todas as notificações e agrupa por tipo
      this.allNotifications.forEach((notification) => {
        if (notification.type.startsWith("BOLETO_")) {
          boletos.push(notification);
          return;
        }
        if (notification.type == "PAYMENT_EXPIRED" || notification.type == "PAYMENT_IDENTIFIED") {
          pagamentos.push(notification);
          return;
        }

        if (notification.type.startsWith("LAST_PAYMENT_")) {
          pagamentos.push(notification);
          return;
        }

        if (notification.type.startsWith("PROPOSAL_") && !notification.days) {
          proposals.push(notification);
          return;
        }
      });

      // Ordena as notificações
      [boletos, proposals].forEach((notifications) => {
        notifications.sort((notificationA, notificationB) => {
          return (
            NOTIFICATIONS_ORDER.indexOf(notificationA.type) -
            NOTIFICATIONS_ORDER.indexOf(notificationB.type)
          );
        });
      });

      let notifications = {
        // Pré-vencimento
        beforeDueDate: beforeDueDate,

        // Pós-vencimento
        afterDueDate: afterDueDate,

        // Exibe em uma lista separada
        others: [
          {
            name: "Propostas",
            notifications: proposals,
          },
          {
            name: "Pagamentos",
            notifications: pagamentos,
          },
          {
            name: "Boletos",
            notifications: boletos,
          },
        ],
      };

      return notifications;
    },

    /**
     * Retorna as notificações de PÓS-VENCIMENTO:
     * A - Alerta pós-vencimento
     * AE - Alerta de expiração
     */
    notificationsBeforeDueDate() {
      // AP - Antecipação de pagamento
      let antecipacaoPagamento = this.allNotifications
        .filter((notification) => {
          if (notification.type != "PROPOSAL_AGREED") {
            return false;
          }

          // Todas as notificações de pré-vencimento devem ter a quantidade de dias
          if (!notification.days) {
            return false;
          }

          return true;
        })
        .sort((notificationA, notificationB) => {
          return notificationA.days - notificationB.days;
        });

      // L - Lembrete de vencimento
      let lembreteVencimento = this.allNotifications
        .filter((notification) => {
          if (notification.type != "PAYMENT_REMINDER") {
            return false;
          }

          if (notification.when != "BEFORE") {
            return false;
          }

          if (!notification.days) {
            return false;
          }

          return true;
        })
        .sort((notificationA, notificationB) => {
          return notificationB.days - notificationA.days;
        });

      // V - Vencimento
      let vencimento = this.allNotifications.filter((notification) => {
        if (notification.type != "PAYMENT_REMINDER") {
          return false;
        }

        if (notification.days) {
          return false;
        }

        return true;
      });

      return antecipacaoPagamento.concat(lembreteVencimento).concat(vencimento);
    },

    /**
     * Retorna as notificações de PÓS-VENCIMENTO:
     * A - Alerta pós-vencimento
     * AE - Alerta de expiração
     */
    notificationsAfterDueDate() {
      // A - Alerta pós-vencimento
      let alertaPosVencimento = this.allNotifications
        .filter((notification) => {
          if (notification.type != "PAYMENT_REMINDER") {
            return false;
          }

          if (notification.when != "AFTER") {
            return false;
          }

          return true;
        })
        .sort((notificationA, notificationB) => {
          return notificationA.days - notificationB.days;
        });

      // AE - Alerta de expiração
      let alertaExpiracao = this.allNotifications.filter((notification) => {
        if (notification.type != "PAYMENT_EXPIRATION") {
          return false;
        }

        if (!notification.days) {
          return false;
        }

        return true;
      });

      return alertaPosVencimento.concat(alertaExpiracao);
    },
  },
  methods: {
    ...mapActions("creditor", {
      loadCreditor: "ActionsLoadCreditor",
    }),

    ...mapMutations("snackbar", {
      showSnackbar: "showSnackbar",
    }),

    /** Carrega a lista de notificações da régua. */
    loadNotifications(ruleId) {
      if (this.loading.notifications) {
        return;
      }

      ruleId = ruleId || this.id;

      this.notifallNotificationsications = [];
      this.loading.notifications = true;
      this.loadingError = false;

      let params = {
        limit: 1000,
        rule_id: ruleId,
      };

      if (!this.showInactiveNotifications) {
        params.active = true;
      }

      console.debug("Carregando as notificações para a régua", ruleId);

      serviceNotification
        .search(params)
        .then((response) => {
          this.allNotifications = response.data.objects;
          this.loadingError = false;

          console.debug(
            "Notificações carregadas:",
            this.allNotifications.length
          );
        })
        .catch((error) => {
          console.error("Não foi possível listar as notificações.", error);
          this.loadingError = true;
        })
        .finally(() => {
          this.loading.notifications = false;
        });
    },

    /** Carrega a régua. */
    loadRule(ruleId) {
      if (this.loading.rule) {
        return;
      }

      console.log("Buscando a régua", ruleId);

      this.loading.rule = true;
      this.allNotifications = [];
      this.rule = {};

      serviceRule
        .get(ruleId)
        .then((response) => {
          console.log("Régua carregada.");
          this.rule = response.data;

          this.loadCreditor(this.rule.creditor_id);
          this.loadRules(this.rule.creditor_id);
        })
        .catch((error) => {
          console.error("Não foi possível buscar a régua.", error);
          this.loadingError = true;
        })
        .finally(() => {
          this.loading.rule = false;
        });
    },

    /** Carrega as réguas do credor. */
    loadRules(creditorId) {
      if (this.loading.rules) {
        return;
      }

      console.log("Buscando as réguas do credor", creditorId);

      this.loading.rules = true;

      let params = {
        creditor_id: creditorId,
        limit: 100,
      };

      serviceRule
        .search(params)
        .then((response) => {
          this.rules = response.data.objects;
          console.debug("Réguas carregadas:", this.rules.length);
        })
        .catch((error) => {
          console.log("Não foi possível buscar as réguas do credor.", error);
          this.loadingError = true;
        })
        .finally(() => {
          this.loading.rules = false;
        });
    },

    /** A régua selecionada foi alterada. */
    ruleChanged(ruleId) {
      console.log("Régua alterada para", ruleId);

      $router.push({ params: { id: ruleId } });

      this.loadNotifications(ruleId);
    },

    /** Cria uma nova régua (versão). */
    createRule() {
      if (this.saving.rule) {
        return;
      }

      console.log("Criando régua.");

      this.saving.rule = true;

      let params = {
        creditor_id: this.creditor.id,
      };

      serviceRule
        .create(params)
        .then((response) => {
          console.log("Régua criada:", response.data.id);

          this.$router.push({
            name: "Rule",
            params: { id: response.data.id },
          });
          this.dialogCreateVersion = false;
        })
        .catch((error) => {
          console.log("Não foi possível criar a régua.", error);
          this.showSnackbar("Não foi possível criar a versão.");
        })
        .finally(() => {
          this.saving.rule = false;
        });
    },

    /** Atualiza a versão da régua do credor. */
    publishCreditorRule(event) {
      event.preventDefault();
      if (this.saving.creditor) {
        return;
      }

      if (!this.rules.description) {
        return;
      }

      console.log(
        "Alterando a régua do credor de",
        this.creditor.rule_id,
        "para",
        this.rule.id
      );

      this.saving.creditor = true;

      let payload = {
        change_description: this.rules.description,
        rule_id: this.rule.id,
      };

      serviceCreditor
        .publishRule(this.creditor.id, payload)
        .then((response) => {
          console.debug("Credor salvo.");
          this.showSnackbar("Versão publicada.");
          this.creditor.rule_id = response.data.rule_id;
          this.closePublishDialog();
        })
        .catch((error) => {
          console.error("Não foi possível salvar o credor.", error);
          this.showSnackbar("Não foi possível salvar o credor.");
        })
        .finally(() => {
          this.saving.creditor = false;
        });
    },
    closePublishDialog(){
      this.$refs.form.resetValidation();
      delete this.rules.description
      this.dialogPublishVersion = false;
    }
  },
};
</script>

<style scoped lang="scss">
.notifications-list {
  padding: 0;
}

.notification {
  &.inactive {
    opacity: 0.5;
  }
}

.versions {
  max-width: 60px;
}

.current-version {
  font-weight: 700;
}
</style>
