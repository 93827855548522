<template>
  <div class="fill-height">
    <v-toolbar tile dark class="qj-bar-color" elevation="0">
      <v-toolbar-title class="text-h5 d-flex align-center mr-10">
        <!--  -->
        <v-btn fab dark text small @click="$refs.calendar.prev()">
          <v-icon small> mdi-chevron-left </v-icon>
        </v-btn>
        <!-- Título -->
        <b style="width: 200px; text-align: center">
          {{ title() }}
        </b>
        <!--  -->
        <v-btn fab dark text small @click="$refs.calendar.next()">
          <v-icon small> mdi-chevron-right </v-icon>
        </v-btn>
      </v-toolbar-title>
      <div style="display: flex">
        <!-- Input data do aceite da proposta -->
        <v-menu
          offset-y
          transition="scale-transition"
          :close-on-content-click="false"
          v-model="menuConfirmation"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dark
              dense
              outlined
              readonly
              hide-details
              label="Aceite da proposta"
              background-color="#00000033"
              v-on="on"
              v-bind="attrs"
              v-model="agreementDateFormatted"
            ></v-text-field>
          </template>
          <v-date-picker
            locale="pt-br"
            @input="menuConfirmation = false"
            v-model="agreementDate"
            :max="dueDate"
            @change="filtersChanged()"
          ></v-date-picker>
        </v-menu>
        <!-- Input do dia do vencimento da proposta -->
        <v-menu
          offset-y
          min-width="auto"
          transition="scale-transition"
          :close-on-content-click="false"
          v-model="menuDueDate"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dark
              dense
              outlined
              readonly
              hide-details
              class="ml-3"
              label="Vencimento"
              background-color="#00000033"
              v-on="on"
              v-bind="attrs"
              v-model="dueDateFormatted"
            ></v-text-field>
          </template>
          <v-date-picker
            locale="pt-br"
            @input="menuDueDate = false"
            v-model="dueDate"
            :min="agreementDate"
            @change="filtersChanged()"
          ></v-date-picker>
        </v-menu>
        <v-col md="3" class="py-0">
          <v-select
            dark
            dense
            outlined
            hide-details
            background-color="#00000033"
            v-model="paymentMethod"
            :items="paymentMethods"
            label="Método de pagamento"
          ></v-select>
        </v-col>
        <v-col md="3" class="py-0">
          <v-select
            dark
            dense
            outlined
            hide-details
            background-color="#00000033"
            v-model="paymentType"
            :items="paymentTypes"
            label="Tipo de pagamento"
          ></v-select>
        </v-col>
        <!-- Botão para testar a simulação -->
        <v-btn
          depressed
          class="ml-2"
          color="primary"
          :disabled="simulating"
          @click="simulate()"
          :loading="simulating"
          >Testar</v-btn
        >
        <v-btn
          style="left: 50px; top: 5px"
          small
          icon
          :to="{ name: 'Rule', params: { id: id } }"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-toolbar>

    <!-- Calendário -->
    <v-calendar locale="pt-br" ref="calendar" v-model="value" class="calendar">
      <template v-slot:day-label="day">
        <div>
          <v-btn
            icon
            :class="{ grey: verifyStartOrEndDay(day.date) }"
            style="font-size: 15px"
          >
            {{ new Date(day.date).getUTCDate() }}
          </v-btn>
        </div>
      </template>
      <template v-slot:day="day">
        <h4 class="blacklist">
          {{ getBlacklistDate(day.date) }}
        </h4>
        <v-row class="px-3 pt-2">
          <v-col
            v-for="notification in getNotifications(day.date)"
            :key="notification.id"
            :cols="getNotifications(day.date).length == 1 ? 12 : 6"
            class="px-1 pb-0 pt-2"
          >
            <!-- DIAS COM 1 NOTIFICAÇÃO -->
            <v-card
              v-if="getNotifications(day.date).length == 1"
              class="elevation-0 notification-type rounded-lg"
              :class="notification.type"
              @click="openNotification(notification.id)"
              style="cursor: pointer"
            >
              <v-card-title class="justify-center text-center subtitle-2 pa-2 pb-1" style="word-break: normal; line-height: 120%;">
                <div>
                  {{ notification.abbreviation }} -
                  {{ notification.description }} 
                  <span v-if="notification.payment_method" class="payments">
                    - {{ notification.payment_method | paymentMethod }}
                  </span> 
                  <span v-if="notification.payment_type" class="payments">
                    - {{ notification.payment_type | paymentType }}
                  </span>
                </div>
              </v-card-title>
              <v-card-text class="text-center px-2 pb-2 pt-0" style="line-height: 120%;">
                <v-icon v-if="notification.sms"
                  >mdi-message-text-outline</v-icon
                >
                <v-icon v-if="notification.email_subject"
                  >mdi-email-outline</v-icon
                >
                <v-icon
                  v-if="notification.push_title || notification.push_body"
                  >mdi-bell-outline</v-icon
                >
                <v-icon v-if="notification.whatsapp_initiative"
                  >mdi-whatsapp</v-icon
                >
              </v-card-text>
            </v-card>

            <!-- DIAS COM MAIS DE 1 NOTIFICAÇÃO -->
            <v-tooltip top v-if="getNotifications(day.date).length > 1">
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  :class="notification.type"
                  class="notification-type justify-center rounded-lg"
                  style="width: 100%; cursor: pointer"
                  @click="openNotification(notification.id)"
                >
                  {{ notification.abbreviation }}
                </v-chip>
              </template>
              <span
                >{{ notification.description }} <br />
                {{ notification.payment_method | paymentMethod }} <br />
                {{ notification.payment_type | paymentType }} <br />
                <v-icon v-if="notification.sms" color="white"
                  >mdi-message-text-outline</v-icon
                >
                <v-icon v-if="notification.email_subject" color="white"
                  >mdi-email-outline</v-icon
                >
                <v-icon
                  v-if="notification.push_title || notification.push_body"
                  color="white"
                  >mdi-bell-outline</v-icon
                >
                <v-icon v-if="notification.whatsapp_initiative" color="white"
                  >mdi-whatsapp</v-icon
                >
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>
    </v-calendar>
  </div>
</template>

<script>
import moment from "moment";
import serviceRule from "@/services/rule";
import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
  props: {
    /** Id da régua. */
    id: {
      required: true,
    },
  },
  data: () => ({
    simulating: false,
    menuDueDate: false,
    menuConfirmation: false,
    dueDate: undefined,
    agreementDate: undefined,
    dueDateFormatted: undefined,
    agreementDateFormatted: undefined,
    paymentMethod: null,
    paymentType: null,
    value: "",
    simulations: [],
    paymentMethods: [
      {
        text: "Todos",
        value: null,
      },
      {
        text: "Boleto",
        value: "BOLETO",
      },
      {
        text: "Débito Automático",
        value: "DEBIT_CARD",
      },
      {
        text: "Primeiro boleto, outros crédito",
        value: "FIRST_BOLETO_OTHERS_CREDIT",
      },
      {
        text: "Primeiro boleto, outros débito automático",
        value: "FIRST_BOLETO_OTHERS_DEBIT",
      },
      {
        text: "Primeiro Pix, outros boleto",
        value: "FIRST_PIX_OTHERS_BOLETO",
      },
      {
        text: "Primeiro Pix, outros débito automático",
        value: "FIRST_PIX_OTHERS_DEBIT",
      },
      {
        text: "Parcele Já",
        value: "PARCELE_JA",
      },
      {
        text: "Crédito",
        value: "CREDIT",
      },
      {
        text: "Pix",
        value: "PIX",
      }          
    ],
    paymentTypes: [
      {
        text: "Todos",
        value: null,
      },
      {
        text: "Cash",
        value: "IN_CASH",
      },
      {
        text: "Colchão",
        value: "INSTALLMENT",
      },
    ],
  }),
  created() {
    this.agreementDate =
      this.$route.query.agreement_date || moment().format("YYYY-MM-DD");
    this.dueDate =
      this.$route.query.due_date ||
      moment().add(7, "days").format("YYYY-MM-DD");
    this.paymentMethod = this.$route.query.payment_method || null;
    this.paymentType = this.$route.query.payment_type || null;

    this.getAllBlackList();
    this.loadRule(this.id);

    if (this.$route.query.agreement_date && this.$route.query.due_date) {
      this.simulate();
    }
  },
  watch: {
    dueDate() {
      this.dueDateFormatted = this.formatDate(this.dueDate);
      if (!this.dueDate) this.events = {};
    },
    agreementDate() {
      this.agreementDateFormatted = this.formatDate(this.agreementDate);
      if (!this.agreementDate) this.events = {};
    },
  },
  computed: {
    ...mapGetters("blacklist", {
      blackListList: "list",
    }),
  },
  methods: {
    ...mapMutations("snackbar", {
      showSnackbar: "showSnackbar",
    }),
    ...mapActions("blacklist", {
      getAllBlackList: "getAll",
    }),
    ...mapActions("creditor", {
      loadCreditor: "ActionsLoadCreditor",
    }),

    /** Carrega a régua. */
    loadRule(ruleId) {
      console.log("Buscando a régua", ruleId);

      serviceRule
        .get(ruleId)
        .then((response) => {
          console.log("Régua carregada.");
          this.loadCreditor(response.data.creditor_id);
        })
        .catch((error) => {
          console.error("Não foi possível buscar a régua.", error);
          alert("Não foi possível buscar a régua.");
        });
    },

    openNotification(id) {
      this.$router.push({
        name: "Notification",
        params: {
          id: id,
        },
      });
    },

    formatDateToBD(data) {
      let dia = data.getDate();
      let mes = data.getMonth() + 1;
      let ano = data.getFullYear();
      if (dia < 10) dia = "0" + dia;
      if (mes < 10) mes = "0" + mes;
      return ano + "-" + mes + "-" + dia;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    title() {
      if (this.$refs.calendar) {
        return this.$refs.calendar.title;
      }

      return moment().format("MMMM YYYY");
    },
    verifyStartOrEndDay(day) {
      const actualDay = new Date(day).toDateString();
      let dueDate = new Date(this.dueDate).toDateString();
      let agreementDate = new Date(this.agreementDate).toDateString();

      return actualDay == dueDate || actualDay == agreementDate;
    },

    /** Faz a simulação das notificações. */
    simulate() {
      if (!this.dueDate || !this.agreementDate) return;

      if (this.simulating) {
        return;
      }

      this.simulating = true;

      this.simulations = [
        {
          date: this.agreementDate,
          notification: { description: "" },
        },
        {
          date: this.dueDate,
          notification: { description: "" },
        },
      ];

      // Atualiza os filtros na URL
      this.$router
        .replace({
          query: {
            agreement_date: this.agreementDate,
            due_date: this.dueDate,
            payment_method: this.paymentMethod,
            payment_type: this.paymentType,
          },
        })
        .catch(() => {
          // Ignora o erro
        });

      let payload = {
        proposal_agreement_date: this.agreementDate,
        payment_due_date: this.dueDate,
        payment_method: this.paymentMethod || null,
        payment_type: this.paymentType || null,
      };

      serviceRule
        .simulate(this.id, payload)
        .then((response) => {
          this.simulations = response.data.simulations;

          if (this.simulations.length == 0) {
            this.showSnackbar("Nenhuma notificação encontrada.");
          }
        })
        .catch(() => {
          this.showSnackbar("Ops, ocorreu um erro!");
        })
        .finally(() => {
          this.simulating = false;
        });
    },

    /** Retorna a lista de notificações para a data informada. */
    getNotifications(date) {
      if (!this.simulations) {
        return [];
      }

      let simulation = this.simulations.find(function (simulation) {
        return simulation.date == date;
      });

      if (simulation) {
        return simulation.notifications;
      }

      return [];
    },

    filtersChanged() {
      this.simulations = [
        {
          date: this.agreementDate,
          notification: {
            description: "",
          },
        },
        {
          date: this.dueDate,
          notification: {
            description: "",
          },
        },
      ];
    },

    /** Retorna o nome do feriado. */
    getBlacklistDate(date) {
      var blacklist = this.blackListList.objects.find(function (blacklistdays) {
        return blacklistdays.date == date;
      });

      if (blacklist) {
        return blacklist.description;
      }
    },
  },
};
</script>

<style lang="scss">
.page-rulesimulation .v-main__wrap > .container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  padding: 0;
}
</style>

<style scoped lang="scss">
.qj-bar-color {
  background: linear-gradient(90deg, #7c4dff 0%, #82b1ff 100%) !important;
}

.calendar {
  height: calc(100vh - 164px);
}

.notification {
  display: inline;
  border-radius: 10px;
}

.description {
  color: #212121;
  display: inline;
  font-size: 14px;
  font-weight: 500;
}

.payment-method,
.payment-type {
  color: #555;
  display: inline;
  font-size: 12px;
  font-weight: 400;
}

.blacklist {
  text-align: center;
  background-color: #d0d0d0;
}

.payments {
  font-weight: 500;
  font-size: 12px;
  color: #757575;
}
</style>
