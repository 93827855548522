<template>
  <div v-if="notifications">
    <v-row
      no-gutters
      :key="index"
      :style="index == 0 ? '' : 'border-top: 1px solid #e1e1e1'"
      v-for="(row, index) in matrixNotifications"
    >
      <v-col
        cols="12"
        xs="12"
        sm="6"
        md="3"
        lg="2"
        xl="1"
        :style="
          'padding-top: 2px;' +
          (notification.there_is_border
            ? ' border-right: 1px solid #e1e1e1'
            : '')
        "
        v-for="notification in row"
        :key="notification.id"
      >
        <div
          class="notification"
          v-bind:class="{ inactive: !notification.active }"
        >
          <!-- Card -->
          <v-card
            elevation="0"
            :to="{ name: 'Notification', params: { id: notification.id } }"
            class="notification-type"
            :class="notification.type"
          >
            <v-card-text style="padding: 5px">
              <h3
                style="
                  font-weight: 500;
                  font-size: 14px;
                  font-family: Roboto;
                  color: #212121;
                  line-height: 16px;
                "
              >
                {{ notification.abbreviation }} - {{ notification.description
                }}<samp
                  style="
                    font-weight: 500;
                    font-size: 12px;
                    font-family: Roboto;
                    color: #757575;
                  "
                  v-if="notification.payment_method"
                >
                  - {{ notification.payment_method | paymentMethod }}</samp
                >
              </h3>
              <div
                style="
                  font-weight: 500;
                  font-size: 12px;
                  font-family: Roboto;
                  color: #757575;
                "
              >
                {{ notification.payment_type | paymentType }}
              </div>

              <div style="margin-top: 2px">
                <v-icon
                  style="font-size: 20px; color: #757575"
                  v-for="icon in notification.icons_midayss"
                  :key="icon"
                  >{{ icon }}</v-icon
                >
              </div>

              <div style="margin-top: 2px">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      dark
                      v-bind="attrs"
                      v-on="on"
                      v-if="notification.email_subject"
                      style="font-size: 20px; color: #757575"
                    >
                      mdi-email-outline
                    </v-icon>
                  </template>
                  <span>Notificação E-mail</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      dark
                      v-bind="attrs"
                      v-on="on"
                      v-if="notification.whatsapp_initiative"
                      style="font-size: 20px; color: #757575"
                    >
                      mdi-whatsapp
                    </v-icon>
                  </template>
                  <span>Notificação Whatsapp</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      dark
                      v-bind="attrs"
                      v-on="on"
                      v-if="notification.sms"
                      style="font-size: 20px; color: #757575"
                    >
                      mdi-message-text-outline
                    </v-icon>
                  </template>
                  <span>Notificação Sms</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      dark
                      v-bind="attrs"
                      v-on="on"
                      v-if="notification.push_title || notification.push_body"
                      style="font-size: 20px; color: #757575"
                    >
                      mdi-bell-outline
                    </v-icon>
                  </template>
                  <span>Notificação Push</span>
                </v-tooltip>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ["notifications"],
  computed: {
    matrixNotifications() {
      var row = [];
      var matrix = [];
      var itemPossibility = 1;

      this.notifications.forEach((notification) => {
        notification.there_is_border =
          itemPossibility != this.maxInRow ? true : false;
        row.push(notification);

        if (itemPossibility == this.maxInRow) {
          matrix.push(row);

          row = [];
          itemPossibility = 1;
        } else itemPossibility++;
      });
      if (row != []) matrix.push(row);

      return matrix;
    },
    maxInRow() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
          return 12;
        case "lg":
          return 6;
        case "md":
          return 4;
        case "sm":
          return 2;
        case "xs":
          return 1;
      }
    },
  },
  methods: {
    click() {},
  },
};
</script>

<style scoped lang="scss">
.qj-bar-color {
  background: linear-gradient(90deg, #7c4dff 0%, #82b1ff 100%) !important;
}

.no-days {
  visibility: hidden;
}

.qj-btn {
  position: absolute;
  right: 2px;
  top: 2px;
  height: 25px;
  width: 25px;
  opacity: 0;
  z-index: 1;
}

.notification {
  padding: 5px;
  position: relative;

  .v-card {
    cursor: pointer;
  }

  &.inactive {
    opacity: 0.5;
  }
}

.notification:hover {
  .qj-btn {
    transition: 0.2s;
    opacity: 1;
  }
}

.notification-type {
  &.PAYMENT_EXPIRATION {
    background-color: #ffe0b2;
  }

  &.PAYMENT_REMINDER {
    background-color: #fff9c4;
  }

  &.PROPOSAL_AGREED {
    background-color: #c8e6c9;
  }
}
</style>
