<template>
  <div class="ma-n3">
    <div v-if="loading" class="d-flex justify-center">
      <v-progress-circular indeterminate></v-progress-circular>
    </div>

    <v-alert v-if="loadingError" type="error" prominent text>
      <v-row align="center">
        <v-col class="grow"> Não foi possível carregar a notificação. </v-col>
        <v-col class="shrink">
          <v-btn color="primary" @click="loadNotification()">
            Tentar novamente
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <div v-if="notification && !loading && !loadingError">
      <v-toolbar flat>
        <v-btn
          icon
          :to="{
            name: 'Rule',
            params: { id: notification.rule_id || this.ruleId },
          }"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title> Voltar para as notificações </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-toolbar-title class="hidden-sm-and-down font-weight-medium">
          <span v-if="notification.abbreviation"
            >{{ notification.abbreviation }} -</span
          >
          {{ notification.description }}
        </v-toolbar-title>

        <v-tooltip v-if="notification.id" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              :to="{
                name: 'NotificationHistory',
                params: { notification_id: notification.id },
              }"
            >
              <v-icon dark>mdi-history</v-icon>
            </v-btn>
          </template>

          <span>Histórico de alterações</span>
        </v-tooltip>

        <v-tooltip v-if="notification.id" bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
              class="font-weight-light text--secondary"
            >
            {{ (notification.updated_at || notification.created_at) | moment("from", "now") }}
            </div>
          </template>

          <span>
            {{ (notification.updated_at || notification.created_at) | moment("lll") }}
          </span>
        </v-tooltip>
      </v-toolbar>

      <div class="d-flex">
        <v-form ref="form" lazy-validation @submit="save">
          <v-card elevation="0">
            <!-- Conteúdo -->
            <v-card-text class="pa-3 qj-notification-form-details" ref="card">
              <v-row class="my-0">
                <v-col cols="12" md="6" class="d-flex align-center">
                  <v-select
                    v-model="notification.type"
                    :items="notificationsType"
                    label="Tipo da notificação"
                    :rules="[
                      () => !!notification.type || 'Este campo é obrigatório',
                    ]"
                    @change="reset_fields()"
                  ></v-select>

                  <v-btn
                    icon
                    target="_blank"
                    href="https://docs.google.com/document/d/e/2PACX-1vTw_Y7TkOAWyMJmwCZ_PKj0YrwkhO_lhsuz8fSIpIBbz8uE0XGY3LW7VMgEEmd3DH6VXdBlPVTLAquO/pub"
                  >
                    <v-icon>mdi-help-circle</v-icon>
                  </v-btn>
                </v-col>

                <v-col cols="12" md="6">
                  <v-checkbox
                    v-model="notification.active"
                    label="Ativo"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row class="my-0">
                <v-col cols="12" class="text-h5 font-weight-medium">
                  Parâmetros
                </v-col>
              </v-row>

              <v-row class="my-0">
                <v-col cols="12" md="1" class="py-0">
                  <v-text-field
                    v-model="notification.days"
                    label="Dias"
                    type="number"
                    min="0"
                    :disabled="!fields.days"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="2" class="py-0">
                  <v-select
                    v-model="notification.days_type"
                    :items="daysType"
                    label="Tipo dos dias"
                    :disabled="!fields.days_type"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="2" class="py-0">
                  <v-select
                    v-model="notification.when"
                    :items="when"
                    label="Quando"
                    :disabled="!fields.when"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="3" class="py-0">
                  <v-select
                    v-model="notification.payment_method"
                    :items="paymentMethod"
                    label="Método de pagamento"
                    :disabled="!fields.payment_method"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-select
                    v-model="notification.payment_type"
                    :items="paymentType"
                    label="Tipo do pagamento"
                    :disabled="!fields.payment_type"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="3" class="py-0" v-if="false">
                  <v-text-field
                    v-model="notification.min_days_after_proposal_agreed"
                    label="Dias após o Aceite da proposta"
                    type="number"
                    :disabled="!fields.min_days_after_proposal_agreed"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3" class="py-0" v-if="false">
                  <v-text-field
                    v-model="notification.min_days_until_due_date"
                    label="Dias até o vencimento"
                    type="number"
                    :disabled="!fields.min_days_until_due_date"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row class="my-0">
                <v-col cols="12" class="text-h5 font-weight-medium">
                  Mensagens
                </v-col>
              </v-row>

              <v-row class="my-0">
                <v-col cols="12" md="6" class="py-0">
                  <v-row class="my-0">
                    <v-col cols="12">
                      <p class="text-h6">SMS</p>
                      <v-textarea
                        dense
                        outlined
                        label="Mensagem"
                        color="deep-purple accent-2"
                        v-model="notification.sms"
                        @click="setSelectedField('sms')"
                        :rules="[validateMessage]"
                        ref="sms"
                        hint="Não pode conter emojis ou caracteres especiais."
                        persistent-hint
                        rows="3"
                        style="min-width: 50px"
                      ></v-textarea>
                      <span
                        style="margin-top: -25px"
                        class="d-flex justify-end"
                        :class="smsContent > maxLength ? 'counter-sms' : ''"
                        >{{ smsContent }} / {{ maxLength }}</span
                      >

                      <div
                        v-if="notification.sms"
                        v-html="showPreview(notification.sms)"
                        class="z-preview"
                      ></div>
                    </v-col>

                    <v-col cols="12">
                      <p class="text-h6">Flash SMS</p>

                      <v-textarea
                        dense
                        outlined
                        label="Mensagem"
                        color="deep-purple accent-2"
                        v-model="notification.flash_sms"
                        @click="setSelectedField('flash_sms')"
                        :rules="[validateMessage]"
                        ref="flash_sms"
                        hint="Não pode conter emojis ou caracteres especiais."
                        persistent-hint
                        rows="3"
                      ></v-textarea>

                      <div
                        v-if="notification.flash_sms"
                        v-html="showPreview(notification.flash_sms)"
                        class="z-preview"
                      ></div>
                    </v-col>

                    <v-col cols="12">
                      <p class="text-h6">E-mail</p>

                      <v-text-field
                        dense
                        outlined
                        label="Assunto"
                        color="deep-purple accent-2"
                        v-model="notification.email_subject"
                        @click="setSelectedField('email_subject')"
                        :rules="[validateMessage]"
                        ref="email_subject"
                        hide-details="auto"
                      ></v-text-field>

                      <div
                        v-if="notification.email_subject"
                        v-html="showPreview(notification.email_subject)"
                        class="z-preview"
                      ></div>
                    </v-col>

                    <v-col cols="12">
                      <div>
                        <v-textarea
                          dense
                          outlined
                          label="URL"
                          color="deep-purple accent-2"
                          v-model="notification.email_asset_url"
                          :rules="rules.url"
                          hint="Exemplo: https://assets.quiteja.com.br/mail/deal/default/proposal-agreed/v2"
                          @click="setSelectedField('email_asset_url')"
                          persistent-hint
                          rows="2"
                        ></v-textarea>
                        <div>
                          <v-btn
                            depressed
                            color="primary"
                            :href="assetEditorUrl"
                            :disabled="!assetEditorUrl"
                            class="mx-1 mt-1"
                            target="_blank"
                          >
                            <v-icon left>mdi-circle-edit-outline</v-icon>
                            Editar
                          </v-btn>

                          <v-btn
                            depressed
                            color="primary"
                            @click="showAsset"
                            class="mt-1"
                          >
                            <v-icon left>mdi-file-import</v-icon>
                            Importar
                          </v-btn>
                        </div>
                      </div>

                      <AssetList
                        :dialog.sync="dialogImport"
                        v-on:selectedUrl="urlAsset($event)"
                      />
                    </v-col>

                    <v-col cols="12">
                      <p class="text-h6">Push</p>

                      <v-text-field
                        dense
                        outlined
                        label="Título"
                        hint="Não é necessário colocar o link no título ou no corpo do push."
                        persistent-hint
                        color="deep-purple accent-2"
                        v-model="notification.push_title"
                        @click="setSelectedField('push_title')"
                        :rules="[validateMessage]"
                        ref="push_title"
                      ></v-text-field>

                      <div
                        v-if="notification.push_title"
                        v-html="showPreview(notification.push_title)"
                        class="z-preview"
                      ></div>
                    </v-col>

                    <v-col cols="12">
                      <v-textarea
                        label="Corpo"
                        hint="Não é necessário colocar o link no título ou no corpo do push."
                        persistent-hint
                        dense
                        outlined
                        color="deep-purple accent-2"
                        v-model="notification.push_body"
                        @click="setSelectedField('push_body')"
                        :rules="[validateMessage]"
                        ref="push_body"
                      ></v-textarea>

                      <div
                        v-if="notification.push_body"
                        v-html="showPreview(notification.push_body)"
                        class="z-preview"
                      ></div>
                    </v-col>

                    <v-col cols="12" class="py-4">
                      <p class="text-h6">WhatsApp</p>

                      <v-text-field
                        dense
                        outlined
                        label="Chave da iniciativa no Dodo 🦤"
                        hint="Exemplo: approved_proposal"
                        persistent-hint
                        color="deep-purple accent-2"
                        v-model="notification.whatsapp_initiative"
                        @click="setSelectedField('initiative_whatsapp')"
                        class="mb-4"
                      >
                        <template v-slot:append-outer>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <a
                                :href="'https://dodo.gorillascode.com/#/customerService2/1/initiative/list?showPreview=' + (notification.whatsapp_initiative || '')"
                                target="_blank"
                                class="text-decoration-none"
                                v-bind="attrs"
                                v-on="on">
                                <v-icon>mdi-open-in-new</v-icon>
                              </a>
                            </template>

                            <span>Ver iniciativa no Dodo</span>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                      <v-textarea
                        type="url"
                        dense
                        outlined
                        label="URL do arquivo"
                        hint="Opcional. Pode ser usado para enviar uma imagem para o cliente. No caso do boleto não é necessário pois o boleto é enviado automaticamente na mensagem do WhatsApp."
                        color="deep-purple accent-2"
                        v-model="notification.whatsapp_params.file_url"
                        @click="setSelectedField('file_url')"
                        :rules="rules.url"
                        persistent-hint
                        rows="2"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  class="py-0 email-asset-url-preview"
                  style="text-align: center"
                >
                  <div v-if="notification.email_asset_url" class="mt-2">
                    Passe o mouse sobre a imagem para ver um exemplo
                  </div>
                  <v-hover v-slot="{ hover }">
                    <v-img
                      class="assets-image"
                      v-if="notification.email_asset_url"
                      v-bind:src="hover ? assetPreviewUrl1 : assetPreviewUrl2"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="blue"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>

                    <div v-else class="text-h6" style="margin-top: 70%">
                      Pré-visualização do asset
                    </div>
                  </v-hover>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <!-- Rodapé -->
            <v-card-actions>
              <v-alert
                v-if="notificacaoExistente.id"
                dense
                type="error"
                class="mb-0"
              >
                Já existe a notificação:
                <router-link
                  :to="{
                    name: 'Notification',
                    params: { id: notificacaoExistente.id },
                  }"
                  target="_blank"
                  class="white--text"
                >
                  Clique aqui
                </router-link>
              </v-alert>

              <v-spacer></v-spacer>

              <div
                v-if="$store.state.enviroment.name == 'production'"
                class="px-4 text--secondary"
              >
                As alterações podem levar até <strong>1 hora</strong> para serem
                aplicadas em produção
              </div>

              <v-btn
                depressed
                color="primary"
                type="submit"
                :disabled="saving || !valid"
                :loading="saving"
              >
                Salvar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>

        <div v-if="verifyFieldSelected(selectedField)">
          <v-toolbar flat color="primary" dark>
            <v-toolbar-title>Variáveis para as mensagens</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon @click="setSelectedField()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-alert type="info" class="ma-0 text-body-2" :icon="false">
            No SMS, push e assunto do e-mail coloque o nome da variável sempre
            dentro de <code>{...}</code>. Exemplo: <code>{name}</code>
            <br /><br />
            No template do e-mail (asset) coloque o nome da variável sempre
            dentro de <code v-pre>{{...}}</code
            >. Exemplo: <code v-pre>{{ name }}</code>
          </v-alert>

          <v-list class="pa-0">
            <div>
              <v-text-field
                flat
                class="px-4"
                placeholder="Pesquisar"
                prepend-inner-icon="mdi-magnify"
                v-model="search"
              ></v-text-field>
            </div>

            <v-divider></v-divider>

            <v-list-item-group class="qj-message-variables">
              <div
                v-for="messageVariable in variableFiltered"
                :key="messageVariable.id"
              >
                <v-list-item
                  three-line
                  @click="concatVaribleToMessage(messageVariable)"
                >
                  <v-list-item-content>
                    <v-list-item-title class="py-2">
                      <span class="qj-variable-name">
                        {{ messageVariable.name }}
                      </span>
                      <span
                        v-if="messageVariable.lenght"
                        class="ml-2 text--secondary"
                      >
                        {{ messageVariable.lenght }} caracteres
                      </span>
                    </v-list-item-title>
                    <v-list-item-subtitle
                      v-html="messageVariable.description"
                      class="mb-2 qj-variable-description"
                    ></v-list-item-subtitle>
                    <v-list-item-subtitle
                      ><strong>Exemplo: </strong
                      >{{ messageVariable.example }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </div>
            </v-list-item-group>
          </v-list>
        </div>
      </div>

      <v-dialog max-width="600" v-model="dialogPreviewMessage">
        <template>
          <v-card>
            <v-toolbar color="primary" dark
              >Pré-visualização da mensagem:</v-toolbar
            >
            <v-card-text>
              <div class="text-h6 pa-4">{{ preview }}</div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="dialogPreviewMessage = false">Fechar</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import serviceNotification from "@/services/notification";
import serviceRule from "@/services/rule";
import AssetList from "@/components/AssetList";
import rules from "@/assets/js/rules";
import { mapMutations, mapActions, mapGetters } from "vuex";
import enviroment from "@/assets/js/enviroment";

export default {
  components: {
    AssetList,
  },

  props: {
    data: {
      type: Object,
      default: null,
    },

    /** Id da notificação. Deve ser nulo se for para a criação de uma nova notificação. */
    id: {
      required: false,
    },

    /** Id da régua. */
    ruleId: {
      required: true,
    },
  },
  data: () => ({
    variableDisabled: false,
    textSms: "",
    textSmsVariable: 0,
    valid: true,
    maxLength: 160,
    converterUrl: enviroment.converterUrl,
    preview: null,
    /** Indica se está carregando a notificação. */
    loading: false,

    selectedField: null,

    /** Indica se houve um erro ao carregar a notificação. */
    loadingError: false,

    /** Notificação editada. */
    notification: {
      sms: "",
      flash_sms: "",
      email_subject: "",
      push_title: "",
      push_body: "",
      whatsapp_params: {},
      active: true,
      type: null,
    },

    notificacaoExistente: {},

    fieldsAcceptedVariable: [
      "sms",
      "flash_sms",
      "email_subject",
      "push_title",
      "push_body",
    ],
    search: "",

    selectedVariable: null,
    nameMessageVariable: null,

    dialogPreviewMessage: false,

    rules: rules,

    /** Indica se está salvando a notificação. */
    saving: false,

    /** Indica estado do asset dialog */
    dialogImport: false,

    notificationsType: [
      {
        header: "Lembretes",
      },
      {
        text: "Vencimento",
        value: "PAYMENT_REMINDER",
        field_activated: {
          payment_method: true,
          days: true,
          days_type: true,
          when: true,
          min_days_after_proposal_agreed: true,
          min_days_until_due_date: true,
          payment_type: true,
          send_next_business_day: true,
        },
      },
      {
        text: "Expiração",
        value: "PAYMENT_EXPIRATION",
        field_activated: {
          payment_method: true,
          days: true,
          days_type: true,
          when: true,
          payment_type: true,
        },
      },
      {
        header: "Informações sobre a Proposta",
      },
      {
        text: "Acordo prorrogado",
        value: "PROPOSAL_EXTENDED",
        field_activated: {
          payment_method: true,
        },
      },
      {
        text: "Promessa existente",
        value: "PROPOSAL_ACTIVE_AGREEMENT",
        field_activated: {
          payment_method: true,
        },
      },
      {
        text: "Proposta aceita",
        value: "PROPOSAL_AGREED",
        field_activated: {
          days: true,
          days_type: true,
          when: true,
          payment_type: true,
          payment_method: true,
        },
      },
      {
        text: "Proposta aguardando",
        value: "PROPOSAL_WAITING",
        field_activated: {
          payment_method: true,
        },
      },
      {
        text: "Proposta confirmada",
        value: "PROPOSAL_CONFIRMED",
        field_activated: {
          payment_method: true,
        },
      },
      {
        text: "Proposta não aprovada: não elegível",
        value: "PROPOSAL_NOT_ELEGIBLE",
        field_activated: {
          payment_method: true,
        },
      },
      {
        text: "Proposta rejeitada",
        value: "PROPOSAL_REJECTED",
        field_activated: {
          payment_method: true,
        },
      },
      {
        text: "Prorrogação de acordo rejeitado",
        value: "PROPOSAL_EXTENSION_REJECTED",
        field_activated: {
          payment_method: true,
        },
      },
      {
        header: "Boletos e Pagamentos",
      },
      {
        text: "Boleto agendado",
        value: "BOLETO_SCHEDULED",
        field_activated: {
          payment_method: true,
          payment_type: true,
        },
      },
      {
        text: "Boleto atualizado",
        value: "BOLETO_CHANGED",
      },
      {
        text: "Boleto enviado",
        value: "BOLETO_SENDED",
      },
      {
        text: "Pagamento identificado",
        value: "PAYMENT_IDENTIFIED",
        field_activated: {
          payment_method: true,
          payment_type: true,
        },
      },
      {
        text: "Pagamento expirado",
        value: "PAYMENT_EXPIRED",
        field_activated: {
          payment_method: true,
        },
      },
      {
        text: "Último pagamento identificado (acordo quitado)",
        value: "LAST_PAYMENT_IDENTIFIED",
      },
    ],
    paymentMethod: [
      {
        text: "Todos",
        value: null,
      },
      {
        text: "Boleto",
        value: "BOLETO",
      },
      {
        text: "Crédito",
        value: "CREDIT",
      },
      {
        text: "Primeiro boleto, outros crédito",
        value: "FIRST_BOLETO_OTHERS_CREDIT",
      },
      {
        text: "Primeiro Pix, outros boleto",
        value: "FIRST_PIX_OTHERS_BOLETO",
      },
      {
        text: "Primeiro Pix, outros débito automático",
        value: "FIRST_PIX_OTHERS_DEBIT",
      },
      {
        text: "Pix",
        value: "PIX",
      },            
      {
        text: "Débito Automático",
        value: "DEBIT_CARD",
      },
      {
        text: "Parcele Já",
        value: "PARCELE_JA",
      },
    ],
    paymentType: [
      {
        text: "Todos",
        value: null,
      },
      {
        text: "Cash",
        value: "IN_CASH",
      },
      {
        text: "Colchão",
        value: "INSTALLMENT",
      },
    ],
    when: [
      {
        text: "-",
        value: null,
      },
      {
        text: "Antes",
        value: "BEFORE",
      },
      {
        text: "Depois",
        value: "AFTER",
      },
    ],
    daysType: [
      {
        text: "-",
        value: null,
      },
      {
        text: "Dias corridos",
        value: "CALENDAR_DAYS",
      },
      {
        text: "Dias úteis",
        value: "BUSINESS",
      },
    ],
  }),

  created() {
    this.getMessageVariable();
    if (this.id) {
      this.loadNotification();
    } else {
      if (this.$route.query.type) {
        this.notification.type = this.$route.query.type;
      }

      if (this.$route.query.when) {
        this.notification.when = this.$route.query.when;
      }
    }
  },

  methods: {
    ...mapMutations("snackbar", {
      showSnackbar: "showSnackbar",
    }),
    ...mapActions("blacklist", {
      getBlacklist: "getBlacklist",
    }),
    ...mapActions("creditor", {
      loadCreditor: "ActionsLoadCreditor",
    }),
    ...mapActions("notification", {
      getAllMessageVariable: "getAll",
    }),
    urlAsset(url) {
      this.notification.email_asset_url = url;
    },

    createAssetPreview(example) {
      if (!this.notification) {
        return null;
      }

      let assetsParams = {};

      this.variableFiltered.forEach((variable) => {
        assetsParams[variable.name] = example
          ? variable.example
          : "{{ " + variable.name + " }}";
      });

      let urlAsset = `${this.notification.email_asset_url}?p=${JSON.stringify(
        assetsParams
      )}`;
      return `${this.converterUrl}/image?url=${urlAsset}`;
    },

    /** Carrega a notificação. */
    loadNotification() {
      console.log("Carregando a notificação", this.id);

      this.loading = true;
      this.loadingError = false;

      serviceNotification
        .get(this.id)
        .then((response) => {
          console.log("Notificação carregada");

          this.notification = response.data;
          this.notification.whatsapp_params =
            this.notification.whatsapp_params || {};

          this.loadRule(this.notification.rule_id);
        })
        .catch((error) => {
          console.error("Não foi possível carregar a notificação.", error);
          this.loadingError = true;
        })
        .finally(() => {
          this.loading = false;
          this.verifyTypeNotifications();
        });
    },

    /** Carrega a régua. */
    loadRule(ruleId) {
      console.log("Buscando a régua", ruleId);

      this.rule = {};

      serviceRule
        .get(ruleId)
        .then((response) => {
          console.log("Régua carregada.");
          this.loadCreditor(response.data.creditor_id);
        })
        .catch((error) => {
          console.error("Não foi possível buscar a régua.", error);
          alert("Não foi possível buscar a régua.");
        });
    },

    async save(event) {
      event.preventDefault();
      if (!this.$refs.form.validate()) return;
      if (this.textSmsVariable > 160) {
        this.showSnackbar(
          "O texto do SMS não pode ultrapassar 160 caracteres."
        );
        return;
      }
      if (this.saving) return;

      this.saving = true;

      let params = {
        limit: 1000,
        type: this.notification.type,
        payment_type: this.notification.payment_type,
        payment_method: this.notification.payment_method,
        rule_id:
          this.ruleId || this.notification.rule_id || this.$route.query.rule,
        days: this.notification.days,
        days_type: this.notification.days_type,
        when: this.notification.when,
        send_next_business_day: this.notification.send_next_business_day,
        deal_tags: this.notification.deal_tags,
      };

      var allNotifications = await serviceNotification
        .search(params)
        .then((response) => {
          return response.data.objects;
        })
        .catch((error) => {
          console.debug(error);
        });

      this.notificacaoExistente = {};

      if (allNotifications.length > 0) {
        let notificacaoExistente = allNotifications[0];

        if (notificacaoExistente.id != this.notification.id) {
          this.notificacaoExistente = notificacaoExistente;
          this.saving = false;
        }
      }

      let payload = {
        days_type: this.notification.days_type,
        days: this.notification.days,
        email_asset_url: this.notification.email_asset_url,
        email_subject: this.notification.email_subject,
        type: this.notification.type,
        min_days_after_proposal_agreed:
          this.notification.min_days_after_proposal_agreed,
        min_days_until_due_date: this.notification.min_days_until_due_date,
        payment_method: this.notification.payment_method,
        payment_type: this.notification.payment_type,
        push_body: this.notification.push_body,
        push_title: this.notification.push_title,
        rule_id: this.ruleId || this.notification.rule_id,
        send_next_business_day: this.notification.send_next_business_day,
        deal_tags: this.notification.deal_tags,
        sms: this.notification.sms,
        whatsapp_initiative: this.notification.whatsapp_initiative,
        whatsapp_params: this.notification.whatsapp_params,
        when: this.notification.when,
        active: this.notification.active,
        flash_sms: this.notification.flash_sms,
      };

      let response = this.notification.id
        ? serviceNotification.update(this.notification.id, payload)
        : serviceNotification.create(payload);

      response
        .then((response) => {
          this.notification = response.data;
          this.notification.whatsapp_params =
            this.notification.whatsapp_params || {};

          console.debug("Notificação salva.");
          this.showSnackbar("Notificação salva com sucesso!");

          // Atualiza a rota com a URL da notificação
          this.$router
            .replace({
              params: { id: this.notification.id },
            })
            .catch(() => {
              // Ignora o erro
            });
        })
        .catch((error) => {
          console.error("Não foi possível salvar a notificação.", error);
          this.showSnackbar("Ops! Houve um erro ao salvar!");
        })
        .finally(() => {
          this.saving = false;
        });
    },
    reset_fields() {
      this.verifyTypeNotifications();
      delete this.notification.days;
      delete this.notification.days_type;
      delete this.notification.when;
      delete this.notification.min_days_after_proposal_agreed;
      delete this.notification.min_days_until_due_date;
      delete this.notification.payment_method;
      delete this.notification.payment_type;
      delete this.notification.send_next_business_day;
    },

    /** Valida as mensagens e retorna se existe algum erro. */
    validateMessage(text) {
      if (!text) {
        return true;
      }

      // Verifica se a quantidade de { e } são iguais
      if ((text.match(/{/g) || []).length != (text.match(/}/g) || []).length) {
        return 'Verifique se faltou abrir ou fechar chaves { } em alguma variável';
      }

      return true;
    },

    verifyTypeNotifications() {
      if (
        this.notification.type == "PROPOSAL_EXTENDED" ||
        this.notification.type == "PROPOSAL_ACTIVE_AGREEMENT" ||
        this.notification.type == "PROPOSAL_AGREED" ||
        this.notification.type == "PROPOSAL_WAITING" ||
        this.notification.type == "PROPOSAL_CONFIRMED" ||
        this.notification.type == "PROPOSAL_NOT_ELEGIBLE" ||
        this.notification.type == "PROPOSAL_REJECTED" ||
        this.notification.type == "PROPOSAL_EXTENSION_REJECTED"
      ) {
        this.paymentMethod.push({
          text: "Primeiro boleto, outros débito automático",
          value: "FIRST_BOLETO_OTHERS_DEBIT",
        });
      }else{
        this.paymentMethod.forEach((method, index) => {
          if(method.value == "FIRST_BOLETO_OTHERS_DEBIT"){
            this.paymentMethod.splice(index)
          }
        })
      }
    },

    getMessageVariable() {
      let params = {
        limit: 1000,
        order: "name",
      };
      this.getAllMessageVariable(params);
    },
    concatVaribleToMessage(variable) {
      const cursorPosition =
        this.$refs[this.selectedField].$refs.input.selectionStart;
      const stringToArray = (this.notification[this.selectedField] || "").split(
        ""
      );
      stringToArray.splice(cursorPosition, 0, `{${variable.name}}`);
      this.notification[this.selectedField] = stringToArray.join("");
      this.$refs[this.selectedField].$refs.input.focus();
    },
    setSelectedField(field) {
      this.selectedField = field;
    },
    verifyFieldSelected(selectedField) {
      var itemSelected = this.fieldsAcceptedVariable.find(
        (items) => items == selectedField
      );
      if (itemSelected) {
        return true;
      } else {
        return false;
      }
    },
    showPreview(message) {
      this.messageVariableList.objects.forEach((variable) => {
        while (message.includes(`{${variable.name}}`)) {
          message = (message || "").replace(
            "{" + variable.name + "}",
            "<strong>" + variable.example + "</strong>"
          );
        }
      });
      return message;
    },
    showAsset() {
      this.dialogImport = true;
    },
  },
  computed: {
    ...mapGetters("notification", {
      messageVariableList: "list",
    }),

    smsContent() {
      let cont = 0;
      this.textSmsVariable = 0;
      this.textSms = this.notification.sms;
      if (this.textSms == null) return;
      this.messageVariableList.objects.forEach((variable) => {
        while (this.textSms.includes(`{${variable.name}}`)) {
          this.textSms = this.textSms.replace(`{${variable.name}}`, "");
          cont += variable.lenght;
          this.textSmsVariable = cont;
        }
      });
      this.textSmsVariable += this.textSms.length;
      return this.textSmsVariable;
    },

    /** Retorna o link para a edição do Asset. */
    assetEditorUrl() {
      if (!this.notification || !this.notification.email_asset_url) {
        return null;
      }

      for (const enviromentName in enviroment.configs) {
        let env = enviroment.configs[enviromentName];

        if (this.notification.email_asset_url.startsWith(env.assetsUrl)) {
          let assetPath = this.notification.email_asset_url.replace(
            env.assetsUrl + "/",
            ""
          );
          return (
            env.assetsAppUrl +
            "/asset?searchTerm=" +
            encodeURIComponent(assetPath)
          );
        }
      }
    },

    assetPreviewUrl1() {
      return this.createAssetPreview(true);
    },

    assetPreviewUrl2() {
      return this.createAssetPreview(false);
    },

    fields() {
      if (this.notification == null) return {};
      if (this.notification.type == null) return {};
      let type = this.notification.type;
      let notificationsType = this.notificationsType.find(function (
        notification
      ) {
        return notification.value == type;
      });
      if (notificationsType == null) return {};
      return notificationsType.field_activated || {};
    },
    variableFiltered() {
      return this.messageVariableList.objects.filter((variable) => {
        if (variable.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
          return true;
        return false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.qj-notification-form-details {
  max-height: calc(100vh - 181px);
  overflow-y: auto;
  overflow-x: hidden;
}

.email-asset-url-preview {
  background-color: #f6f6f6;
}

.assets-image {
  border-radius: 4px;
  background-size: contain;
  background-position: center;
  width: 600px;
}

.qj-message-variables {
  max-height: calc(100vh - 372px);
  overflow-y: auto;
  overflow-x: hidden;
  width: 500px;

  .qj-variable-name {
    background-color: #fff;
    border: 1px solid;
    border-color: var(--v-primary-base);
    color: var(--v-primary-base);
    border-radius: 8px;
    padding: 4px 8px;
  }

  .qj-variable-description {
    -webkit-line-clamp: unset;
  }
}

.z-preview {
  background-color: #e3f2fd;
  border-radius: 4px;
  font-size: 13px;
  margin: 24px 0 24px;
  padding: 8px 8px 4px;
  position: relative;
}

.z-preview::before {
  background-color: #e3f2fd;
  border-radius: 4px;
  content: "Pré-visualização";
  font-size: 10px;
  font-weight: 500;
  padding: 0 8px;
  position: absolute;
  left: 0px;
  top: -10px;
}

.counter-sms {
  color: red;
}
</style>
