<template>
  <v-data-table
    :headers="headers"
    :items="blackListList.objects"
    sort-by="date"
    class="mx-auto elevation-1"
    hide-default-footer
    :items-per-page="1000"
    style="max-width: 750px;"
  >
    <template v-slot:no-data>
      <h3>Nenhum feriado cadastrado</h3>
    </template>
    <template v-slot:[`item.date`]="{ item }">
      {{ formatDate(item.date) }}
    </template>
    <template v-slot:[`item.menu`]="{ item }">
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-icon small class="mr-2" v-on="on" @click="openEditDialog(item)">
            mdi-pencil
          </v-icon>
          <v-icon small v-on="on" @click="openDialogDelete(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-menu>
    </template>
    <template v-slot:top>
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-dialog max-width="500px" v-model="dialog">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              Novo
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-form ref="form" lazy-validation>
                <v-container>
                  <v-row>
                    <v-menu
                      offset-y
                      transition="scale-transition"
                      :close-on-content-click="false"
                      v-model="menuConfirmation"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          style="margin-top: 53px"
                          dense
                          outlined
                          readonly
                          clearable
                          label="Data"
                          @click:clear="date = undefined"
                          v-on="on"
                          v-bind="attrs"
                          v-model="dateFormatted"
                          :rules="[
                            () =>
                              !!date || 'Este campo é obrigatório',
                          ]"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        locale="pt-br"
                        color="deep-purple accent-2"
                        @input="menuConfirmation = false"
                        v-model="date"
                      ></v-date-picker>
                    </v-menu>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        style="margin-top: 35px"
                        label="Descrição"
                        v-model="description"
                        :rules="[
                          () =>
                            !!description || 'Este campo é obrigatório',
                        ]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancelar
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
                :disabled="saving"
              >
                Salvar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog max-width="500px" v-model="dialogDelete">
          <v-card>
            <v-card-title class="text-h5">
              Deseja deletar o feriado?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" @click="closeDialogDelete"
                >Cancelar</v-btn
              >
              <v-btn color="blue darken-1" @click="deleteItem()">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
  </v-data-table>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
  created() {
    this.getBlackList();
  },
  data: () => ({
    saving: false,
    dialog: false,
    dateFormatted: undefined,
    date: undefined,
    menuConfirmation: false,
    description: "",
    dialogDelete: false,
    editedIndex: -1,
    headers: [
      {
        text: "Data",
        align: "start",
        sortable: false,
        value: "date",
        width: "150px",
      },
      {
        text: "Descrição",
        value: "description",
        sortable: false,
      },
      {
        text: "",
        value: "menu",
        align: "right",
        sortable: false,
      },
    ],
  }),
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
      if (!this.date) this.events = {};
    },
  },
  computed: {
    ...mapGetters("blacklist", {
      blackListList: "list",
      selectedBlackList: "selected",
    }),
  },
  methods: {
    ...mapMutations("snackbar", {
      showSnackbar: "showSnackbar",
    }),
    ...mapMutations("blacklist", {
      setSelectedBlackList: "setSelected",
    }),
    ...mapActions("blacklist", {
      getAllBlackList: "getAll",
      saveBlackList: "save",
      deleteBlackList: "delete",
    }),
    formatDateToBD(data) {
      let dia = data.getDate();
      let mes = data.getMonth() + 1;
      let ano = data.getFullYear();
      if (dia < 10) dia = "0" + dia;
      if (mes < 10) mes = "0" + mes;
      return ano + "-" + mes + "-" + dia;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    getBlackList() {
      let limit = {
        limit: 1000,
      };
      this.getAllBlackList(limit);
    },
    save(event) {
      event.preventDefault();
      if (!this.$refs.form.validate()) return;

      if (this.saving) return;
      this.saving = true;

      let payload = {
        id: this.selectedBlackList?.id || undefined,
        description: this.description,
        date: this.date,
      };
      this.saveBlackList(payload)
        .then(() => {
          this.showSnackbar("Feriado salvo com sucesso!");
          this.close();
        })
        .catch((error) => {
          this.showSnackbar("Ops! Houve um erro ao salvar!");
        })
        .finally(() => {
          this.saving = false;
        });
    },
    openEditDialog(item) {
      this.setSelectedBlackList(item);
      this.date = item.date;
      this.description = item.description;
      this.dialog = true;
    },
    close() {
      this.date = undefined;
      this.description = "";
      this.dialog = false;
      this.$refs.form.resetValidation();
    },
    deleteItem() {
      let item = this.selectedBlackList;
      this.deleteBlackList(item)
        .then(() => {
          let index = this.blackListList.objects.indexOf(item);
          this.blackListList.objects.splice(index, 1);
          this.dialogDelete = false;
          this.showSnackbar("Feriado removido com sucesso!");
        })
        .catch(() => {
          this.showSnackbar("Ops! Ocorreu um erro!");
        });
    },
    openDialogDelete(item) {
      this.setSelectedBlackList(item);
      this.dialogDelete = true;
    },
    closeDialogDelete() {
      this.dialogDelete = false;
    },
  },
};
</script>

<style scoped>
</style>
