<template>
  <v-sheet
    elevation="2"
    class="mx-auto pa-4 mt-12"
    style="max-width: 600px;">
    <!-- Formulário para criar um novo credor -->
    <v-form ref="form" lazy-validation @submit="save">
      <v-row class="pt-3">
        <!-- Nome -->
        <v-row no-gutters class="mx-3">
          <v-col class="pr-2">
            <v-text-field
              required
              dense
              outlined
              label="Nome"
              color="deep-purple accent-2"
              v-model="creditor.name"
              autofocus
              :rules="[() => !!creditor.name || 'Campo obrigatório']">
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4" v-if="!creditor.id">
            <v-text-field
              type="number"
              dense
              outlined
              label="Identificador"
              color="deep-purple accent-2"
              v-model="creditorNewId"
              hint="Use o mesmo ID da QuiteJá"
              persistent-hint></v-text-field>
          </v-col>
        </v-row>
      </v-row>

      <div class="text-subtitle-1 mt-8 mb-4">
        Dias de tolerância para o pagamento
      </div>

      <v-row class="pt-3">
        <v-row no-gutters class="mx-3">
          <!-- Limite de dias para pagamento da entrada -->
          <v-col cols="12" md="3" class="pr-2">
            <v-text-field
              type="number"
              min="0"
              dense
              outlined
              label="Entrada"
              color="deep-purple accent-2"
              v-model="creditor.down_payment_tolerance_days"></v-text-field>
          </v-col>
          <!-- Tipo da entrada -->
          <v-col cols="12" md="3" class="pr-4">
            <v-select
              dense
              outlined
              label="Dias"
              color="deep-purple accent-2"
              item-color="deep-purple accent-2"
              :items="daysType"
              v-model="creditor.down_payment_tolerance_days_type">
              <template slot="selection" slot-scope="data">
                {{ data.item | daysType }}
              </template>
              <template slot="item" slot-scope="data">
                {{ data.item | daysType }}
              </template>
            </v-select>
          </v-col>

          <!-- Limite de dias para pagamento das parcelas -->
          <v-col cols="12" md="3" class="pr-2">
            <v-text-field
              type="number"
              min="0"
              dense
              outlined
              label="Parcelas"
              color="deep-purple accent-2"
              v-model="creditor.installment_tolerance_days"></v-text-field>
          </v-col>
          <!-- Tipo das parcelas -->
          <v-col cols="12" md="3">
            <v-select
              dense
              outlined
              label="Dias"
              color="deep-purple accent-2"
              item-color="deep-purple accent-2"
              :items="daysType"
              v-model="creditor.installment_tolerance_days_type">
              <template slot="selection" slot-scope="data">
                {{ data.item | daysType }}
              </template>
              <template slot="item" slot-scope="data">
                {{ data.item | daysType }}
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-row>

      <div class="d-flex mt-8">
        <v-spacer></v-spacer>
        <!-- Botão para salvar os dados -->
        <v-btn
          depressed
          color="primary"
          type="submit"
          :disabled="saving"
          :loading="saving">Salvar</v-btn>
      </div>
    </v-form>
  </v-sheet>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

let NEW_CREDITOR = {
  down_payment_tolerance_days: 10,
  down_payment_tolerance_days_type: "CALENDAR_DAYS",
  installment_tolerance_days: 30,
  installment_tolerance_days_type: "CALENDAR_DAYS",
};

export default {
  data: () => ({
    /** Credor sendo editado. */
    creditor: Vue.util.extend({}, NEW_CREDITOR),

    /** Id do credor que será criado. */
    creditorNewId: null,

    /** Tipos de dias para os limites de pagamento. */
    daysType: [
      "BUSINESS",
      "CALENDAR_DAYS",
    ],
  }),

  computed: {
    ...mapGetters("creditor", {
      creditors: "getAll",
      saving: "getSaving",
    }),
  },

  created() {
    // Busca o id do último credor
    if (this.$route.params.id == "new") {
      this.loadAllCreditors().then(() => {
        let lastCreditorId = Math.max.apply(Math, this.creditors.map((creditor) => {
          return creditor.id;
        }))

        this.creditorNewId = lastCreditorId + 1;
      });
    } else {
      this.loadCreditor(this.$route.params.id);
    }
  },

  beforeRouteUpdate(to, from, next) {
    if (to.params.id == "new") {
      this.creditor = Vue.util.extend({}, NEW_CREDITOR);
    } else {
      this.loadCreditor(to.params.id);
    }

    return next();
  },

  methods: {
    ...mapActions("creditor", {
      ActionsLoadCreditor: "ActionsLoadCreditor",
      loadAllCreditors: "ActionsLoadAllCreditors",
      createCreditor: "ActionsCreateCreditor",
      saveCreditor: "ActionsSaveCreditor",
    }),

    ...mapMutations("snackbar", {
      showSnackbar: "showSnackbar",
    }),

    loadCreditor(id) {
      this.ActionsLoadCreditor(id).then((creditor) => {
        this.creditor = creditor;
      }).catch(() => {
        this.showSnackbar("Não foi possível buscar o credor.");
      });
    },

    save(e) {
      e.preventDefault();

      if (!this.$refs.form.validate()) return;

      let payload = {
        down_payment_tolerance_days_type: this.creditor.down_payment_tolerance_days_type,
        down_payment_tolerance_days: this.creditor.down_payment_tolerance_days,
        id: this.creditorNewId || this.creditor.id,
        installment_tolerance_days_type: this.creditor.installment_tolerance_days_type,
        installment_tolerance_days: this.creditor.installment_tolerance_days,
        name: this.creditor.name,
      };

      let response = (this.creditorNewId || !this.creditor.id)
        ? this.createCreditor(payload)
        : this.saveCreditor(payload);

      response
        .then((response) => {
          this.showSnackbar("Credor salvo.");

          this.creditorNewId = null;

          // Atualiza a rota com o id do novo credor
          if (this.$route.params.id == "new") {
            this.$router.replace({
              params: { id: response.data.id },
            }).catch(() => {
              // Ignora o erro
            });
          }
        })
        .catch(() => {
          this.showSnackbar("Não foi possível salvar o credor.");
        })
    },
  },
};
</script>

<style scoped lang="scss">
</style>
