<template>
  <div>
    <div
      v-if="loadingError"
      class="pl-6"
      style="margin-left: calc(50vh); margin-top: 50px; margin-right: calc(50vh);"
    >
      <v-alert type="error" text>
        <v-row align="center">
          <v-col class="grow">
            Não foi possível carregar a lista de histórico.
          </v-col>
          <v-col class="shrink">
            <v-btn color="primary" @click="getCreditorRuleHistory()">
              Tentar novamente
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </div>
    <v-data-table
      :headers="headers"
      :items="creditorRuleHistoryList.objects"
      class="mx-auto elevation-1"
      :items-per-page="10"
      style="max-width: 900px"
      v-if="!loadingError"
      :loading="loading"
      loading-text="Carregando..."
      sort-by="updated_at"
      :sort-desc="true"
    >
      <template v-slot:no-data>
        <h3>Nenhum histórico de régua encontrado...</h3>
      </template>
      <template v-slot:[`item.updated_at`]="{ item }">
        {{ item.updated_at | moment("lll") }}
      </template>
      <template v-slot:[`item.menu`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">            
            <v-btn rounded color="primary" dark v-on="on" :to="{name: 'Rule', params: { id: item.rule_id }}">
              Ver a versão
            </v-btn>
          </template>
        </v-menu>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  created() {
    this.getCreditorRuleHistory();
  },
  data: () => ({
    headers: [
      {
        text: "Versão",
        align: "start",
        sortable: false,
        value: "rule.version",
        width: "50px",
      },
      {
        text: "Descrição",
        align: "start",
        sortable: false,
        value: "change_description",
        width: "300px",
      },
      {
        text: "Publicado por",
        align: "start",
        sortable: false,
        value: "updated_by",
        width: "150px",
      },
      {
        text: "Publicado em",
        align: "start",
        sortable: true,
        value: "updated_at",
        width: "200px",
      },
      {
        text: "",
        value: "menu",
        align: "right",
        sortable: false,
      },
    ],
  }),
  computed: {
    ...mapGetters("creditor_rule_history", {
      creditorRuleHistoryList: "list",
      loadingError: "loadingError",
      loading: "loading",
    }),
  },
  methods: {
    ...mapActions("creditor_rule_history", {
      getAllCreditorRuleHistory: "getAll",
    }),
    getCreditorRuleHistory() {
      let limit = {
        creditor_id: this.$route.params.id,
        limit: 30,
      };
      this.getAllCreditorRuleHistory(limit);
    },
  },
};
</script>

<style scoped lang="scss"></style>
