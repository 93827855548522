import http from "@/plugins/axios";

const VERSION = "/v1";

export default {
  /**
   * Retorna uma régua especifica.
   * 
   * @param {*} id o id da régua.
   * @returns 
   */
   get(id) {
    return http.api.get(VERSION + "/rule/" + id);
  },

  search(params) {
    return http.api.get(VERSION + "/rule", { params });
  },

  /**
   * Cria uma régua.
   * 
   * @param {*} payload 
   * @returns 
   */
   create(payload) {
    return http.api.post(VERSION + "/rule", payload);
  },

  simulate(ruleId, payload) {
    return http.api.post(VERSION + "/rule/" + ruleId + "/simulate", payload);
  },
}
