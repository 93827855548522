export default {
    email: [
        (v) => /.+@.+\..+/.test(v) || "E-mail deve ser válido.",
    ],
    url: [     
        (v) => {
            if(!v) return true;
            return /^$|(http(s)?:\/\/)?(?:www\.)?.{1,256}\.[a-zA-Z0-9()]{1,6}\b(.*)$/.test(v) || 
            /(?:^|\s)((https?:\/\/)?(?:localhost|[\w-]+(?:\.[\w-]+)+)(:\d+)?(\/\S*)?)$/.test(v) ||
            "O campo deve ser preenchido com uma URL."
        },
    ],
};